<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      램 포인트로 전환하기<router-link
        to=""
        @click.native="$router.push('/mypage/my_info')"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_36">
      <div class="sect_box">
        <div class="box">
          <h4>램 포인트로 전환 가능한 내 리뷰 포인트는 현재</h4>
          <p>{{ won(parseInt(userPoint)) }}<span>P</span></p>
        </div>
        <div class="box">
          <h4>이 중에서 램 포인트로 전환할 포인트는</h4>
          <p>
            <input
              type="text"
              name=""
              placeholder="P"
              @input="checkPoint"
              ref="point_input"
            />
          </p>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="setChangePoint"
          >램 포인트 전환하기</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      point: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getRewardRammPoint(true);
  },
  computed: {
    ...mapState("mypage", [
      "result",
      "msg",
      "reviewPoint",
      "userPoint",
      "totalPage",
      "myInfo",
    ]),
  },
  methods: {
    checkPoint(e) {
      const number = /^[0-9]/g;
      const max = Math.floor(this.userPoint / 100) * 100;
      let input = e.target.value.replace(/,/g, "");
      if (input.length <= 0) {
        return false;
      }
      if (!number.test(input)) {
        this.$toast.default(
          "램 포인트로 전환할 포인트는 숫자만 입력 가능합니다."
        );
        e.target.value = "";
        return false;
      }
      let point = parseInt(input);
      if (point > parseInt(max)) {
        e.target.value = this.won(max);
        return false;
      }
      e.target.value = this.won(point);
      this.point = point;
    },
    won(str) {
      return this.$won(str);
    },
    async getRewardRammPoint(reset = false) {
      await this.$store.dispatch("mypage/getRewardRammPoint", {
        page: 0,
        sort: "",
        reset,
      });
    },
    setChangePoint() {
      if (parseInt(this.point) % 100 !== 0) {
        this.$toast.default("전환할 포인트는 100단위로 입력해주세요.");
        this.point = Math.floor(this.point / 100) * 100;
        this.$refs["point_input"].value =
          this.point > 100 ? this.won(this.point) : "";
        return false;
      }
      if (parseInt(this.point) < 100) {
        this.$toast.default("램 포인트 전환은 100포인트  부터 가능합니다.");
        return false;
      }
      if (parseInt(this.point) > parseInt(this.userPoint)) {
        this.$toast.default("보유한 내 리뷰 포인트가 부족합니다.");
        return false;
      }
      this.$confirm("램 포인트로 전환을 진행 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/setChangePoint", {
            point: this.point,
          });
          this.$toast.default(this.msg);
          if (!this.result) {
            return false;
          }
          this.getRewardRammPoint();
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style></style>
